












































import Vue, { PropType } from "vue";
import {
  Agenda,
  ISpeakerUpsertRequest,
  SpeakerAdminResponse,
} from "@/clients/clients";
import { extend, setInteractionMode, ValidationProvider } from "vee-validate";
import { required } from "vee-validate/dist/rules";

extend("required", { ...required, message: "This field is required" });
setInteractionMode("lazy");

export default Vue.extend({
  name: "WebinarAgendaForm",
  components: {
    ValidationProvider,
  },
  data: (): { form: Agenda } => ({
    form: new Agenda(),
  }),
  props: {
    existing: Object as PropType<Agenda>,
    speakers: Array as PropType<
      ISpeakerUpsertRequest[] | SpeakerAdminResponse[]
    >,
  },
  created() {
    if (this.existing) {
      this.form = Object.assign({}, this.form, this.existing);
    }
  },
  computed: {
    speakerNames(): string[] {
      if (!this.speakers?.length) return [];
      return [...this.speakers].map(
        (value: ISpeakerUpsertRequest | SpeakerAdminResponse) => {
          return [value.title, value.fullName, value.postNominalLetters]
            .filter((p) => p?.length ?? 0)
            .join(" ");
        }
      );
    },
  },
});
