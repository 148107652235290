




































































import Vue from "vue";
import { mapActions } from "vuex";
import {
  WebinarsAdminClient,
  Agenda,
  ApiException,
  SpeakerAdminResponse,
  WebinarFullDetailsAdminResponse,
  WebinarUpdateAgendaRequest,
} from "@/clients/clients";
import { ValidationObserver } from "vee-validate";
import WebinarAgendaForm from "@/components/forms/Webinars/WebinarCreate/WebinarAgendaForm.vue";
import { loginRequest } from "@/configs/authConfig";
import { httpClientFactory } from "@/services/http/http";
import AppPageLoading from "@/components/layout/AppPageLoading.vue";
import AppPageBanner from "@/components/layout/AppPageBanner.vue";
import AppPage from "@/components/layout/AppPage.vue";
import AppErrorNotice from "@/components/indicators/AppErrorNotice.vue";

export default Vue.extend({
  name: "WebinarEditAgendaView",
  components: {
    AppErrorNotice,
    AppPage,
    AppPageBanner,
    AppPageLoading,
    WebinarAgendaForm,
    ValidationObserver,
  },
  props: {
    id: String,
  },
  data: (): {
    loading: boolean;
    webinar: WebinarFullDetailsAdminResponse | null;
    errorMessage: string;
    forms: Agenda[];
    speakers: SpeakerAdminResponse[];
  } => ({
    loading: false,
    webinar: null,
    errorMessage: "",
    forms: [],
    speakers: [],
  }),
  async created() {
    if (!this.id) {
      await this.$router.push("/webinars");
      return;
    }
    try {
      this.loading = true;
      this.webinar = await this.getWebinar(this.id);
      this.speakers = this.webinar?.speakers ?? [];
      this.forms = this.webinar?.agendas ?? [];
      this.loading = false;
    } catch (e) {
      this.errorMessage = (e as ApiException).message;
    }
  },
  methods: {
    ...mapActions("Webinar", ["getWebinar", "getSettings", "reset"]),
    async onSubmit(): Promise<void> {
      const agenda = this.forms.filter((form) => {
        let hasValues = false;
        for (const [key, value] of Object.entries(form)) {
          hasValues = value !== null && value !== undefined;
        }
        return hasValues;
      });

      this.loading = true;
      try {
        const tokenRequest = await this.$auth?.authClient.acquireTokenSilent(
          loginRequest
        );
        const accessToken = tokenRequest?.accessToken;
        const client = httpClientFactory(WebinarsAdminClient, accessToken);
        const request = new WebinarUpdateAgendaRequest();
        request.init({
          id: this.id,
          agendas: agenda,
        });
        const response = await client.updateWebinarAgenda(this.id, request);
        await this.$router.push(`/webinars/${response.id}`);
      } catch (e) {
        this.loading = false;
        this.errorMessage = `An error occurred: ${(e as ApiException).message}`;
      }
    },
    addAgenda(): void {
      this.forms.push(new Agenda());
    },
    deleteAgenda(index: number): void {
      this.forms.splice(index, 1);
    },
  },
});
