





















import Vue, { PropType } from "vue";

export default Vue.extend({
  name: "AppErrorNotice",
  props: {
    title: { type: String, default: "" },
    message: { type: String, default: "" },
    messages: { type: Array as PropType<string[]>, default: () => [] },
  },
  data: () => ({}),
  computed: {
    errors(): string[] {
      return [this.message, ...this.messages].filter((p) => p?.length);
    },
  },
});
